
<template>
  <CalendarHeatmap
    style="width: 90%; margin: 2%"
    :values="values"
    :max="max"
    :range-color="colors"
    tooltip-unit="flomos"
    :end-date="new Date()"
  />
</template>

<script>
import { CalendarHeatmap } from 'vue3-calendar-heatmap'
import Axios from "axios";
//引入qs库
import qs from "qs";
const axios = Axios.create({
  //以application / x-www-form-urlencoded格式发送数据
  headers: {
    "content-type": "application/x-www-form-urlencoded",
  },
  //带cookie请求
  withCredentials: true,
});

//请求拦截
axios.interceptors.request.use((config) => {
  //如果发送的数据本身就是FormData格式的数据 那么就不需要用qs模块进行转换
  if (typeof config.data == "object" && config.data.__proto__.toString() == "[object FormData]") return config;
  if (config.method == "post") {
    //如果是post请求 使用qs模块转换发送的数据
    config.data = qs.stringify(config.data);
  }
  return config;
});

export default {
  name: 'App',
  components: {
    CalendarHeatmap
  },
  data() {
    return {
      max: 5,
      values: [
        // { date: '2017-9-22', count: 1 },
      ],
      // undefined, 0, 1, 2, 3, ...max
      colors: ['#ebedf0', '#e7f3eb', '#9be9a8', '#40c463', '#30a14e', '#216e39']
    }
  },
  mounted() {
    console.log('mounted')
    let url = "https://service-ltfhen52-1256398804.gz.apigw.tencentcs.com/flomo"//+"?year=2022"

    axios.get(url).then((response) => {
      // console.log('res:', response.data)
      this.values = response.data.list;
    }).catch((response) => {
      console.log(response);
    })
  },
  updated() {
    console.log('updated!')
  },
}
</script>

<style>
.vch__container .vch__legend {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vch__container .vch__external-legend-wrapper {
  margin: 0 8px;
}

svg.vch__wrapper {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 10px;
}
svg.vch__wrapper .vch__months__labels__wrapper text.vch__month__label {
  font-size: 10px;
}
svg.vch__wrapper .vch__days__labels__wrapper text.vch__day__label,
svg.vch__wrapper .vch__legend__wrapper text {
  font-size: 9px;
}
svg.vch__wrapper .vch__months__labels__wrapper text.vch__month__label,
svg.vch__wrapper .vch__days__labels__wrapper text.vch__day__label,
svg.vch__wrapper .vch__legend__wrapper text {
  fill: #767676;
}
svg.vch__wrapper rect.vch__day__square:hover {
  stroke: #555;
  stroke-width: 1px;
}
svg.vch__wrapper rect.vch__day__square:focus {
  outline: none;
}

.vue-tooltip-theme.tooltip {
  display: block !important;
  z-index: 10000;
}
.vue-tooltip-theme.tooltip .tooltip-inner {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #ebedf0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  line-height: 16px;
  padding: 14px 10px;
}
.vue-tooltip-theme.tooltip .tooltip-inner b {
  color: white;
}
.vue-tooltip-theme.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}
.vue-tooltip-theme.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}
.vue-tooltip-theme.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.vue-tooltip-theme.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}
.vue-tooltip-theme.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.vue-tooltip-theme.tooltip[x-placement^="right"] {
  margin-left: 5px;
}
.vue-tooltip-theme.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.vue-tooltip-theme.tooltip[x-placement^="left"] {
  margin-right: 5px;
}
.vue-tooltip-theme.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.vue-tooltip-theme.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.vue-tooltip-theme.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
